.shutter-btn-container {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: #fff;
    left: calc(50vw - 1.5rem);
    position: absolute;
    bottom: 5rem;
    padding: 0.25rem;
  }
  
  .shutter-btn {
    width: 100%;
    height: 100%;
    border: 1px solid rgb(39, 38, 38);
    border-radius: 50%;
    transition: border 0.3s;
    cursor: pointer;
  }
  
  .shutter-btn:active {
    border: 4px solid rgb(39, 38, 38);
  }
  
  .camera-container {
    background-color: #241b1c;
  }
  
  .capture-thumbnail {
    position: absolute;
    bottom: 3rem;
    right: 1rem;
    width: 4rem;
    height: 4rem;
    text-align: center;
    border: 1px solid #fff;
  }
  
  .capture-thumbnail img {
    width: auto;
    height: 100%;
  }
  
  .capture-preview {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .close-preview {
    color: #fff;
    padding: 0;
    border-radius: 50%;
    top: 1rem;
    left: 25rem;
    position: absolute;
    border: none;
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
    width: 3rem;
    height: 3rem;
  }
  
  .disclaimer {
    position: absolute;
    bottom: 0;
    background: rgb(255 255 255 / 25%);
    padding: 1rem;
    width: 100vw;
    max-width: 30rem;
  }
  
  .camera-container video {
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .camera-error {
    z-index: 1;
    position: absolute;
    color: #fff;
    padding: 1rem;
    top: calc(50vh - 75px);
  }