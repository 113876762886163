.contact-form {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 1.5rem;
  color: #241b1c;
}

.contact-form input,
.contact-form button {
  border-radius: 0.5rem;
  border: 1px solid #169ef8;
  padding: 1rem;
  font-size: 1.25rem;
}

.contact-form textarea {
  height: 10rem;
  padding: 1rem;
  border: 1px solid #169ef8;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.contact-form button {
  background-color: #169ef8;
  color: #fff;
  font-size: 1.5rem;
  justify-content: center;
  display: flex;
  gap: 0.5rem;
}
.message-success {
  align-self: center;
  text-align: center;
  color: #169ef8;
}

.email-link {
  color: #169ef8;
}

.chat-icon {
  font-size: 12rem;
}

.mail-icon {
  margin-top: 2px;
  font-size: 1.5rem;
}
