.work {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem 1.5rem 1.5rem;
}

.work .logo img {
  width: 10rem;
}

.work-header {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 1.5rem;
}

.work-title {
  font-size: 1.75rem;
  font-weight: 800;
}

.work-type {
  font-size: 1rem;
  color: #ccc;
}

.work .section {
  margin: 1rem 0;
}

.work .section-header {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.work .tech-tile img {
  width: 2rem;
}

.work .tech-tile {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  align-items: center;
}

.work .tech-tiles-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.responsibilities {
  padding-left: 20px;
}

.responsibilities-item {
  margin-bottom: 0.75rem;
}
