.recents {
  padding: 1.5rem;
  color: #241b1c;
}

.recent-item {
  display: flex;
  gap: 0.5rem;
  margin: 1rem 0;
  padding: 1rem 0;
  align-items: center;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  cursor: pointer;
}

.contact-info {
  flex: 1;
}

.contact-name {
  font-weight: 800;
  font-size: 1.2rem;
}

.contact-type,
.contact-time {
  color: #aaa;
}

.contact-time {
  display: flex;
  align-items: flex-end;
  gap: 0.25rem;
}

.info-icon {
  line-height: 0.8rem;
  color: #3e91ce;
}

.contact-time {
  display: flex;
  align-items: flex-end;
  gap: 0.25rem;
}

span.contact-icon {
  line-height: 0.8rem;
}

.call-dock {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  padding: 0.25rem 1rem 2rem;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100vw;
  max-width: 30rem;
  color: #aaa;
  background-color: #fefefe;
  border-top: 1px solid #ccc;
  cursor: not-allowed;
}

.call-dock-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.call-dock-item.highlight {
  color: #0079fc;
}

.call-dock-icon {
  font-size: 1.5rem;
}

.call-dock-label {
  font-size: 0.8rem;
}

.contact-msg {
  font-size: 1.25rem;
  color: #241b1c;
}
