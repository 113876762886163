.testimonials-container {
  overflow-y: auto;
  height: 100vh;
  max-width: 30rem;
}

.testimonial {
  margin: 1rem;
  background-color: #241b1c;
  color: #fff;
  padding: 1rem;
  border-radius: 1rem;
  font-size: 1.75rem;
  line-height: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
}

.testimonial h3 {
  font-size: 2.5rem;
  text-align: center;
}

.testimonial img {
  width: 10rem;
}
