$bgColor: linear-gradient(
  135deg,
  hsl(317deg 94% 67%) 0%,
  hsl(326deg 96% 70%) 7%,
  hsl(338deg 98% 73%) 15%,
  hsl(347deg 83% 70%) 24%,
  hsl(344deg 49% 56%) 37%,
  hsl(335deg 51% 37%) 55%,
  hsl(324deg 72% 28%) 72%,
  hsl(321deg 69% 31%) 87%,
  hsl(319deg 66% 33%) 100%
);

@font-face {
  font-family: appleFont;
  src: url(../../public/fonts/sf-pro-text_regular.woff2);
}

.homescreen-container {
  font-family: appleFont;
  user-select: none;
  background-image: $bgColor;
  width: 100vw;
  height: 100vh;
  max-width: 30rem;
  color: #fff;
  font-size: 1.25rem;
  backface-visibility: hidden;
  max-width: 30rem;
  margin: auto;
  position: relative;
}

.homescreen-container .apps {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  grid-gap: 1rem 1.25rem;
}

.nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 1rem;
}

.nav-bar div {
  flex: 1;
}

.nav-bar-pill {
  background-color: black;
  border-radius: 1rem;
  margin: 0 1.5rem;
  position: relative;
  transition: transform 0.25s;
}

.nav-bar-pill img {
  width: 1.25rem;
  position: absolute;
  left: 0.5rem;
  top: 0.25rem;
}

.nav-bar-pill.expand,
.nav-bar-pill:hover {
  transform: scaleX(1.3);
}

.nav-bar-right {
  display: flex;
  font-size: 1.75rem;
  color: #fff;
  gap: 0.5rem;
  justify-content: right;
  padding-right: 0.75rem;
}

.app-container {
  margin-top: 5rem;
}

.dock {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
}