.grid-2048 {
  border: 1px solid;
  border-collapse: collapse;
}

.grid-2048 td {
  border: 0.75rem solid #baa;
  width: 6rem;
  height: 6rem;
  background-color: #dcb;
  font-size: 2.5rem;
  color: #fff;
  font-weight: 800;
}

.controls {
  margin: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.controls button {
  width: 6rem;
  height: 6rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  border: none;
  font-size: 2rem;
}

.board {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.score-container {
  background-color: #bbaaaa;
  padding: 1rem;
  color: #fff;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  text-align: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 1.5rem;
}

.score {
  font-size: 1.5rem;
  font-weight: 800;
}

.new-game-btn {
  background-color: #938383;
  padding: 0.5rem 1rem;
  color: #fff;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
}

.game-over {
  height: 27.5rem;
  position: absolute;
  width: calc(100% - 3rem);
  margin: 1.5rem;
  top: 12rem;
  background-color: #ffffff50;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: none;
}

.game-over-msg {
  font-size: 3.5rem;
  margin-bottom: 3rem;
  font-weight: 800;
  color: #938383;
}
