.weatherContainer {
  height: 10rem;
  font-size: 0.875rem;
  padding: 1rem;
  margin: 1.25rem;
  border-radius: 1rem;
  background-image: linear-gradient(
    180deg,
    hsl(210deg 90% 28%) 0%,
    hsl(212deg 67% 34%) 10%,
    hsl(211deg 58% 37%) 20%,
    hsl(210deg 53% 40%) 30%,
    hsl(210deg 49% 43%) 40%,
    hsl(209deg 46% 46%) 50%,
    hsl(208deg 44% 48%) 60%,
    hsl(207deg 43% 50%) 70%,
    hsl(207deg 45% 52%) 80%,
    hsl(206deg 48% 55%) 90%,
    hsl(206deg 50% 56%) 100%
  );
}

.weatherContainer .icon img {
  width: 2rem;
  margin: -0.5rem;
  padding-top: 0.25rem;
}

.row {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
}

.temp-container {
  height: 2.625rem;
}

.temp {
  font-size: 3rem;
  line-height: 2rem;
}

.forecast-container {
  display: flex;
  justify-content: space-evenly;
}

.forecast {
  text-align: center;
}
