.twitter-container {
  background-color: #3e91ce;
  color: #fff;
  justify-content: center;
  display: flex;
  padding: 2rem;
  text-align: center;
  width: 100vw;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  max-width: 30rem;
}

.profile-pic {
  border-radius: 50%;
  width: 8rem;
}
