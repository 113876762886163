.canvas-container {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 100vh;
}

canvas {
  touch-action: none;
}

.iframe-wrapper iframe {
  width: 480px;
  height: 1034px;
  border: none;
  border-radius: 60px;
  background: #fff;
}

.iframe-wrapper iframe.iphone {
  visibility: hidden;
}

.iframe-wrapper iframe.show {
  visibility: visible;
}

.use-portrait-msg {
  display: none;
}

@media only screen and (max-height: 640px) and (orientation: landscape) {
  .iframe-window {
    display: none !important;
  }

  .use-portrait-msg {
    margin-top: 1em;
    display: block;
    width: 20rem;
    font-size: 5rem;
    color: #fff;
  }
}
