.full-screen-container.sudoku-board {
  padding: 2rem;
  display: flex;
  flex-direction: column;
}

.sudoku-board input {
  margin: 0;
  padding: 0;
}

.SudokuBoard {
  clear: both;
}

.boxRow {
  clear: both;
  width: 400px;
}

.fieldRow {
  clear: both;
}

.SudokuBox {
  float: left;
  border: 2px solid black;
}

.SudokuField {
  float: left;
  width: 40px;
  height: 40px;

  padding: 0;
  margin: 0;

  line-height: 40px;
  font-size: 1.8em;
  text-align: center;
  vertical-align: super;
}

.SudokuField.userInputField {
  border: thin solid grey;
  color: grey;
}

.userInputField input.selected {
  outline: 5px solid green;
}

.SudokuField.gameOutputField {
  border: thin solid grey;
  color: black;
}

.SudokuField input {
  border: none;
  color: grey;
  height: 40px;
  width: 40px;

  font-size: 1em;
  text-align: center;
  vertical-align: super;
}

.collided {
  background-color: pink;
}

.sudoku-controls {
  clear: both;
  padding-top: 3rem;
  display: flex;
  justify-content: space-between;
}

.sudoku-board button {
  background: #fff;
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 1rem;
}

.sudoku-board button:hover {
  background-color: #efefef;
}

.sudoku-header {
  margin: 1.5rem 0;
  display: flex;
  justify-content: flex-end;
}

.game-logo {
  text-align: center;
}

.game-logo img {
  height: 4rem;
}

.sudoku-inputs {
  display: flex;
  margin: 1rem 0;
  gap: 1rem 2rem;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 60%;
  align-self: center;
}

.sudoku-inputs button {
  font-size: 2rem;
}
