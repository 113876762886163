$iconSize: 6rem;

.folder {
  display: flex;
  background-color: #000000b3;
  padding: 0.5rem;
  border-radius: 1rem;
  gap: 0 0.3rem;
  width: calc(#{$iconSize} - 1.25rem);
  height: calc(#{$iconSize} - 1.25rem);
  flex-wrap: wrap;
  overflow: hidden;
  align-items: flex-start;
}

.folder .app-tile {
  width: calc(4rem / 3);
  height: auto;
}

.folder .app-tile .icon-label {
  display: none;
}

.folder-container.open .folder .app-tile .icon-label {
  display: block;
}

.folder-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 0.5rem;
  min-width: #{$iconSize};
}

.fore-ground {
  width: #{$iconSize};
  height: #{$iconSize};
  position: absolute;
  border-radius: 1rem;
  cursor: pointer;
}

.folder-container.open .fore-ground {
  display: none;
}

.folder-container.open .folder .app-tile {
  width: #{$iconSize};
}

.folder-container.open .folder {
  width: 20rem;
  height: auto;
  overflow: visible;
  grid-gap: 1rem;
  padding: 1.2rem;
}

.folder-container.open {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(0 0 0 / 75%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  padding-top: 15rem;
  align-items: center;
  flex-direction: column;
  z-index: 10;
}
