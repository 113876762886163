@font-face {
  font-family: appleFont;
  src: url(../public/fonts/sf-pro-text_regular.woff2);
}

html,
body {
  font-family: appleFont;
}

.full-screen-container {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow-y: auto;
  max-width: 30rem;
}

.flex-container {
  display: flex;
}

// animations

@keyframes breathe {
  0% {
    scale: 1;
  }
  50% {
    scale: 1.5;
  }
  100% {
    scale: 1;
  }
}

@keyframes blink {
  0% {
    scale: 1.2;
  }
  25% {
    scale: 1;
  }
  50% {
    scale: 1.2;
  }
  75% {
    scale: 1;
  }
  100% {
    scale: 1.2;
  }
}