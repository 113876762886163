.app-tile {
  width: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.app-tile.normalise-size {
  padding: 5px;
  box-sizing: border-box;
}

.app-tile img {
  width: 100%;
  margin-bottom: 0.5rem;
  border-radius: 25%;
  cursor: pointer;
}

.app-tile.blink img {
  animation: blink 3s infinite;
}
