.linkedin-container {
  position: relative;
}

.connect-btn {
  position: absolute;
  top: 22.75rem;
  left: 2rem;
  padding: 0.25rem 2rem;
  background-color: #0766c2;
  color: #fff;
  border-radius: 2rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
