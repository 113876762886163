.date-time {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.date {
  font-size: 1.5rem;
}

.time {
  font-size: 6rem;
  font-weight: bold;
}

