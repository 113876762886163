.modal-container {
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 30rem;
  background-color: #fff;
  top: 0;
  left: 0;
  visibility: hidden;
  transition: all 0.3s;
  transform-origin: top left;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-container.open {
  visibility: visible;
  transform: translate3d(0, 0, 0) scale(1) !important;
  z-index: 99;
}

.close-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: grab;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem 0;
  z-index: 100;
  flex-direction: column;
}

.close-bar {
  border: 3px solid;
  border-radius: 4px;
  width: 8rem;
  padding: 0;
}

.close-text {
  position: absolute;
  top: -2rem;
  top: -0.5rem;
  animation: slideUp 2s 3;
}

@keyframes slideUp {
  0% {
    top: -0.25rem;
  }

  25% {
    top: -0.75rem;
  }
  50% {
    top: -1.25rem;
  }

  95% {
    top: -2rem;
  }
  99% {
    top: -0.25rem;
  }
}
