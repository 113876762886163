
.splash-screen {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 30rem;
  }
  
  .splash-screen-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #fff;
  }
  
  .splash-screen-content img {
    width: 10rem;
  }